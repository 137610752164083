import React from 'react';
import { Drawer } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import GroupIcon from '@material-ui/icons/Group';
import SmsIcon from '@material-ui/icons/Sms';
import NavItem from './NavItem';
import SubNav from './SubNav';

const drawerWidth = 240;

const Navigation: React.FC = () => {
  const classes = useStyles();
  return (
    <Drawer
      variant='permanent'
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <nav className={classes.nav}>
        <CampaignList />
        <ToolsList />
        <PeaneauAdminList />
      </nav>
    </Drawer>
  );
};

const CampaignList = () => (
  <SubNav title='Campaigns'>
    <NavItem ext={true} path='/launch' icon={AddCircleOutlinedIcon} label='Launch Campaign' />
    <NavItem ext={true} path='/manage' icon={EditOutlinedIcon} label='Manage Campaigns' />
    <NavItem ext={true} path='/manageArchived' icon={FolderOutlinedIcon} label='Archived Campaigns' />
  </SubNav>
);

const ToolsList = () => (
  <SubNav title='Tools'>
    <NavItem ext={true} path='/metrics' icon={EqualizerIcon} label='Metrics Dashboard' />
    <NavItem ext={true} path='/smsInbox' icon={SmsIcon} label='SMS Messaging' />
    <NavItem ext={true} path='/memberships' icon={GroupIcon} label='Memberships' />
  </SubNav>
);

const PeaneauAdminList = () => (
  <SubNav title='Paneau Admin'>
    <NavItem ext={true} path='/launch' label='Backdoor' />
    <NavItem ext={true} path='https://driverdash.paneau.io/dash' label='Driver Dashboard v1' />
    <NavItem path='/admin/driverdash' label='Driver Dashboard v2' />
    <NavItem path='/admin/enterprise' label='Android Enterprise' />
    <NavItem ext={true} path='/verifyImages' label='Verify Screenshots' />
    <NavItem ext={true} path='/adminManage' label='Live Content Dashboard' />
    <NavItem ext={true} path='/sms/inbox_adv' label='SMS Inbox' />
    <NavItem ext={true} path='/adminTTView' label='Our Devices' />
    <NavItem ext={true} path='/globalMetrics' label='Metrics Dashboard' />
    <NavItem ext={true} path='/adminReport' label='Toggle Checker' />
    <NavItem ext={true} path='/couponcodes' label='Coupon Codes' />
  </SubNav>
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      'width': drawerWidth,
      'marginTop': '56px',
      '@media (min-width: 0px) and (orientation: landscape)': {
        marginTop: '48px'
      },
      '@media (min-width: 600px)': {
        marginTop: '64px'
      }
    },
    nav: {
      position: 'relative'
    }
  })
);

export default Navigation;
