import { Card, CardContent, Typography, makeStyles, Theme, createStyles } from '@material-ui/core';
import React from 'react';
import Note from 'admin/common/types/Note';

const NotesBox: React.FC<Note> = props => {
  const classes = useStyles();
  return (
    <div style={{ paddingBottom: '0px' }}>
      <Card>
        <CardContent>
          <Typography component='p'>{props.note}</Typography>
          <Typography component='p'>{props.date}</Typography>
          <Typography component='p' className={classes.signOff}>
            -{props.author}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    signOff: {
      fontStyle: 'italic',
      fontSize: 'small'
    }
  })
);

export default NotesBox;
