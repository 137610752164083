import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import 'react-table/react-table.css';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import DriverTable from '../DriverTable';
import CsvDownload from 'react-json-to-csv';
import moment from 'moment';
import DatePicker, { defaultDateRange, DateRange, formatDate } from 'shared/components/DatePicker';
import DriverResponse from 'admin/common/types/DriverResponse';
import useSafeFetch from 'shared/hooks/useSafeFetch';
import RouteLoader from 'shared/components/RouteLoader';
import { formatForAmCharts } from 'admin/common/util/AmChartsUtil';

am4core.useTheme(am4themes_animated);

const buildGraph = () => {
  let chart = am4core.create('chartdiv', am4charts.XYChart);

  chart.paddingRight = 20;

  let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.grid.template.location = 0;
  dateAxis.title.text = 'Date';

  let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.title.text = 'Active Drivers';

  let series = chart.series.push(new am4charts.LineSeries());
  series.dataFields.dateX = 'date';
  series.dataFields.valueY = 'value';

  series.tooltipText = '{valueY.value}';
  chart.cursor = new am4charts.XYCursor();

  let scrollbarX = new am4charts.XYChartScrollbar();
  scrollbarX.series.push(series);
  chart.scrollbarX = scrollbarX;

  return chart;
};

const makeReport = (drivers: any) => {
  if (drivers === undefined) {
    return {};
  }
  // CSV ordering
  return drivers.map((driver: DriverResponse) => {
    return {
      username: driver.username,
      name: driver.name,
      phone_number: driver.phone_number,
      create_date: driver.create_date,
      is_active: driver.is_active.toString(),
      live_status: driver.live_status,
      tablet_software_version: driver.tablet_software_version,
      average_week_drive_hours: driver.average_week_drive_hours.toString(),
      drive_hours: driver.drive_hours.toString(),
      idle_hours: driver.idle_hours.toString()
    };
  });
};

const DriverMain: React.FC = () => {
  const { get } = useSafeFetch();
  const [dateRange, setDateRange] = useState<DateRange>(defaultDateRange);
  const [drivers, setDrivers] = useState<Array<DriverResponse>>();
  const [error, setError] = useState<string | null>(null);
  const chart = useRef<any>(null);
  const refreshGraphInterval = useRef<number | undefined>(undefined);

  useEffect(() => {
    setDriverData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const setDriverData = async () => {
    try {
      let queryString = '';
      if (dateRange !== undefined) {
        queryString = `?from_date=${formatDate(dateRange.startDate)}&to_date=${formatDate(dateRange.endDate)}`;
      }
      let response = await get(`${process.env.REACT_APP_PORTAL}/drivers${queryString}`);

      const json = await response.json();
      setDrivers(json.drivers);
    } catch (e) {
      if (!e.cancelled) {
        console.log(e);
        setError(`An error occurred fetching driver data. Please check network traffic to debug.`);
      }
    }
  };

  useEffect(() => {
    if (drivers) {
      setChartData();
    }
    return () => {
      clearInterval(refreshGraphInterval.current);
      chart.current?.dispose();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drivers, dateRange]);

  const refreshChart = async (dateRange: DateRange) => {
    try {
      const queryString =
        dateRange === undefined
          ? ''
          : `?from_date=${formatDate(dateRange.startDate)}&to_date=${formatDate(dateRange.endDate)}`;
      let response = await get(`${process.env.REACT_APP_PORTAL}/driver/active${queryString}`);
      chart.current.data = formatForAmCharts(await response.json());
    } catch (e) {
      if (!e.cancelled) {
        setError('An error occurred fetching chart data. Please check network traffic to debug.');
        console.log(e);
      }
    }
  };

  const setChartData = async () => {
    chart.current = buildGraph();
    refreshChart(dateRange);
    refreshGraphInterval.current = setInterval(refreshChart, 10000000);
  };

  const dateSelected = (startDate: moment.Moment, endDate: moment.Moment) => {
    setDateRange({ startDate, endDate });
  };

  if (!drivers && error) {
    return (
      <Alert elevation={5} variant='filled' severity='error'>
        {error}
      </Alert>
    );
  }

  if (!drivers) {
    return <RouteLoader />;
  }

  return (
    <div>
      <div id='chartdiv' style={{ width: '100%', height: '500px' }}></div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <DatePicker initDateRange={dateRange} onDateSelected={dateSelected} />
        <div style={{ padding: '12px' }}>
          <CsvDownload data={makeReport(drivers)}>Make Report</CsvDownload>
        </div>
      </div>
      <DriverTable data={drivers} dateRange={dateRange}></DriverTable>
    </div>
  );
};

export default DriverMain;
