import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  DialogContentText,
  makeStyles,
  Theme,
  createStyles
} from '@material-ui/core';
import LoadingButton from '../LoadingButton';
import Alert from '@material-ui/lab/Alert';

export interface ConfirmationDialogProps {
  title: string;
  description: string;
  verifyTextLabel?: string;
  verifyText?: string;
  confirmText?: string;
  open: boolean;
  onConfirm: () => Promise<any>;
  onClose: () => void;
  classes?: Record<'paper', string>;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = props => {
  const { onClose, onConfirm, open, verifyTextLabel, verifyText, confirmText, ...other } = props;
  const [confirmed, setConfirmed] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(false);
  const inputRef = React.createRef<HTMLElement>();
  const classes = useStyles();
  const inputClasses = useInputStyles();

  const handleEntering = () => {
    if (inputRef.current != null) {
      inputRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = async () => {
    setConfirmed(true);
    try {
      await onConfirm();
      onClose();
    } catch (e) {
      setError(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth='sm'
      onEntering={handleEntering}
      aria-labelledby='confirmation-dialog-title'
      open={open}
      {...other}
    >
      <DialogTitle id='confirmation-dialog-title'>{props.title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{props.description}</DialogContentText>
        {Boolean(props.verifyText) && (
          <TextField
            classes={inputClasses}
            inputRef={inputRef}
            id='verify-text'
            label={props.verifyTextLabel || 'Confirm Value'}
            onChange={handleChange}
          />
        )}
        {error && (
          <Alert className={classes.alert} elevation={0} variant='filled' severity='error'>
            An error occurred. Please try again.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color='primary'>
          Cancel
        </Button>
        <LoadingButton
          onClick={handleOk}
          color='primary'
          disabled={Boolean(props.verifyText) && value !== props.verifyText}
          loading={confirmed}
        >
          {props.confirmText || 'Ok'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const useInputStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    }
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      marginTop: '24px'
    }
  })
);

export default ConfirmationDialog;
