import React from 'react';
import { List, ListSubheader, makeStyles, Theme, createStyles } from '@material-ui/core';

interface SubNavProps {
  title: string;
}

const SubNav: React.FC<SubNavProps> = props => {
  const classes = useStyles();
  return (
    <List
      dense={true}
      aria-labelledby='nested-list-subheader'
      subheader={
        <ListSubheader component='div' id='nested-list-subheader'>
          {props.title}
        </ListSubheader>
      }
      className={classes.root}
    >
      {props.children}
    </List>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    }
  })
);

export default SubNav;
