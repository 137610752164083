import React, { useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import LoadingButton from 'shared/components/LoadingButton';

interface NotesFormProps {
  onSubmit: (note: string) => void;
}

const NotesForm: React.FC<NotesFormProps> = props => {
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const onSubmit = async () => {
    const toSubmit = note;
    setLoading(true);
    try {
      await props.onSubmit(toSubmit);
      setNote('');
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <>
      <div className={classes.container}>
        <label className={classes.label}>Note:</label>
        <textarea className={classes.textArea} value={note} onChange={v => setNote(v.target.value)} />
      </div>
      <div>
        <LoadingButton loading={loading} onClick={onSubmit} variant='contained' color='secondary' disabled={loading}>
          Submit Note
        </LoadingButton>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'center',
      marginBottom: '1rem'
    },
    label: {
      marginRight: '0.5rem'
    },
    textArea: {
      width: '60%',
      height: '8rem'
    }
  })
);

export default NotesForm;
