import React from 'react';
import { CircularProgress, makeStyles, Theme, createStyles } from '@material-ui/core';

const RouteLoader: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.progress}>
      <CircularProgress size='4rem' />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      height: '80vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
);

export default RouteLoader;
