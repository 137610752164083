import { Container } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import 'react-table/react-table.css';
import { gapi } from 'admin/core/GoogleApi';
import DeviceTable from './DeviceTable';
import useAndroidEnterpriseData from 'admin/androidenterprise/hooks/useAndroidEnterpriseData';
import { Link } from 'react-router-dom';

const getPolicyFunc = () =>
  gapi()
    .client.androidmanagement.enterprises.policies.get({
      name: `${process.env.REACT_APP_ANDROID_ENTERPRISE_POLICY_NAME}`
    })
    .then((resp: any) => resp.result);

const DevicesPage: React.FC = () => {
  const { error, data } = useAndroidEnterpriseData<any>(getPolicyFunc);

  if (error) {
    return (
      <Alert elevation={5} variant='filled' severity='error'>
        An error occurred while fetching the policy. Please refresh the page and try again.
      </Alert>
    );
  }

  return (
    <Container>
      <h1>Android Enterprise Devices</h1>
      {data && (
        <div>
          Current policy version:{' '}
          <b>
            <Link to='/admin/enterprise/policy'>{data.version}</Link>
          </b>
        </div>
      )}
      <DeviceTable />
    </Container>
  );
};

export default DevicesPage;
