import React from 'react';
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { navigateTo } from 'admin/common/util/navigation';
import { useHistory } from 'react-router-dom';

interface NavItemProps {
  ext?: boolean;
  path: string;
  label: string;
  icon?: any;
}

const NavItem: React.FC<NavItemProps> = props => {
  const history = useHistory();
  const onClick = () =>
    props.ext
      ? navigateTo(props.path.startsWith('http') ? props.path : `${process.env.REACT_APP_PORTAL}${props.path}`)
      : history.push(props.path);
  return (
    <ListItem button onClick={onClick}>
      {props.icon && (
        <ListItemIcon>
          <props.icon />
        </ListItemIcon>
      )}
      <ListItemText>{props.label}</ListItemText>
    </ListItem>
  );
};

export default NavItem;
