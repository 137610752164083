import React, { useState } from 'react';
import moment from 'moment';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { DateRangePicker } from 'react-dates';

export interface DateRange {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

export const defaultDateRange = {
  startDate: moment().add(-14, 'days'),
  endDate: moment()
};

interface DatePickerProps {
  initDateRange: DateRange;
  onDateSelected: (startDate: moment.Moment, endDate: moment.Moment) => void;
}

export const formatDate = (date: moment.Moment): string => date.format('YYYY-MM-DD');

const DatePicker: React.FC<DatePickerProps> = props => {
  let [focusedInput, setFocusedInput] = useState(null);
  let [dateRange, setDateRange] = useState<DateRange>(props.initDateRange);

  const handleDatesChange = ({ startDate, endDate }: { startDate: any; endDate: any }) => {
    setDateRange({ startDate, endDate });
  };

  const handleFocusChange = (focusedInput: any) => {
    setFocusedInput(focusedInput);
  };

  return (
    <div style={{ padding: '10px' }}>
      <DateRangePicker
        startDate={dateRange.startDate}
        startDatePlaceholderText='Start Date'
        startDateId='START_DATE'
        endDate={dateRange.endDate}
        endDateId='END_DATE'
        endDatePlaceholderText='End Date'
        onDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        onFocusChange={handleFocusChange}
        displayFormat={'MM/DD/YYYY'}
        hideKeyboardShortcutsPanel={true}
        numberOfMonths={2}
        block={false}
        small={false}
        withFullScreenPortal={false}
        anchorDirection={'left'}
        orientation={'horizontal'}
        enableOutsideDays={false}
        isOutsideRange={day => false}
        keepOpenOnDateSelect={true}
        onClose={() => props.onDateSelected(dateRange.startDate, dateRange.endDate)}
      />
    </div>
  );
};

export default DatePicker;
