import React from 'react';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import ActiveAction from 'admin/androidenterprise/types/ActiveAction';
import { gapi } from 'admin/core/GoogleApi';

const deleteDevice = (name: string): Promise<any> => {
  return gapi()
    .client.androidmanagement.enterprises.devices.delete({
      name: `${process.env.REACT_APP_ANDROID_ENTERPRISE_NAME}/devices/${name}`
    })
    .then((resp: any) => console.log(resp.result));
};

const rebootDevice = (name: string): Promise<any> => {
  return gapi()
    .client.androidmanagement.enterprises.devices.issueCommand({
      name: `${process.env.REACT_APP_ANDROID_ENTERPRISE_NAME}/devices/${name}`,
      type: 'REBOOT'
    })
    .then((resp: any) => console.log(resp.result));
};

const CommandConfirmationDialog: React.FC<{ action: ActiveAction; onClose: () => void }> = props => {
  const { action } = props;
  const title =
    action?.action === 'reboot'
      ? `Are you sure you want to reboot ${action?.id}?`
      : `Are you sure you want to delete ${action?.id}?`;

  const description =
    action?.action === 'reboot'
      ? `This will remotely reboot the tablet.`
      : `This will factory reset the tablet and remove it from android enterprise.`;

  const onConfirm = (): Promise<any> => {
    if (action?.action === 'reboot') {
      return rebootDevice(action?.name);
    }
    if (action?.action === 'delete') {
      return deleteDevice(action?.name);
    }
    throw new Error('Unsupported action.');
  };

  const confirmText = action?.action === 'reboot' ? 'Reboot' : 'Delete';

  return (
    <ConfirmationDialog
      title={title}
      description={description}
      verifyText={action?.id}
      verifyTextLabel='Verify the name of the device.'
      open={Boolean(action?.action)}
      onClose={props.onClose}
      onConfirm={onConfirm}
      confirmText={confirmText}
    />
  );
};

export default CommandConfirmationDialog;
