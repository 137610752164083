import React from 'react';
import moment from 'moment';

const DataList: React.FC<{ data: any }> = ({ data }) => {
  return renderList(data);
};

const renderList = (obj: any) => {
  let output: any[] = [];
  for (const property in obj) {
    let value = obj[property];
    if (typeof value === 'object') {
      value = renderList(value);
    } else if (canParseDate(value)) {
      value = moment(new Date(value)).format('MMMM Do YYYY, h:mm:ss a');
    } else {
      value = `${value}`;
    }
    output = [
      ...output,
      <li key={output.length}>
        <b>{property}:</b> {value}
      </li>
    ];
  }
  return <ul>{output}</ul>;
};

const canParseDate = (dateStr: string) => {
  return new RegExp(/[\d]{4}-[\d]{2}-[\d]{2}T[\d]{2}:[\d]{2}:[\d]{2}.[\d]{3}Z/).test(dateStr);
};

export default DataList;
