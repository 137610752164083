import React, { useState } from 'react';
import { Button, makeStyles, createStyles, Theme } from '@material-ui/core';
import useSafeFetch from 'shared/hooks/useSafeFetch';

interface PayDriverCellProps {
  id: string;
  defaultAmount?: string;
}

export enum Status {
  Pending,
  Paid,
  Error,
}

const PayDriverCell: React.FC<PayDriverCellProps> = (props) => {
  const { post } = useSafeFetch();
  const [value, setValue] = useState(props.defaultAmount || 50);
  const [status, setStatus] = useState<Status | null>(null);
  const classes = useStyles();

  const pay = async () => {
    try {
      setStatus(Status.Pending);
      const response = await post('/pay_driver', {
        body: JSON.stringify({
          amount: value,
          username: props.id,
        }),
      });
      console.log('the response to pay_driver was');
      console.log(response);
      const { status } = response;
      if (status >= 200 && status < 300) {
        setStatus(Status.Paid);
      } else {
        setStatus(Status.Error);
      }
    } catch (e) {
      if (!e.cancelled) {
        setStatus(Status.Error);
        console.log(e);
      }
    }
  };

  return (
    <div className={classes.payDriverContainer} onClick={(e: any) => e.stopPropagation()}>
      <div className={classes.dollarSign}>$</div>
      <input
        className={classes.payInput}
        type='number'
        defaultValue={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button
        className={`${classes.payButton} ${status === Status.Paid ? 'paid' : ''} ${
          status === Status.Pending ? 'pending' : ''
        }`}
        onClick={pay}
        disabled={status === Status.Paid || status === Status.Pending}
      >
        {status === Status.Paid ? 'Paid' : 'Pay'}
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dollarSign: {
      display: 'inline-block',
      marginRight: '4px',
      fontSize: '18px',
    },
    payDriverContainer: {
      textAlign: 'center',
    },
    payInput: {
      display: 'inline-block',
      width: '55px',
      borderRadius: '3px',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      padding: '5px',
      textAlign: 'left',
      marginRight: '10px',
    },
    payButton: {
      'display': 'inline-block',
      'color': '#FFF',
      'padding': '5px 20px',
      'borderRadius': '4px',
      'border': 'none',
      'backgroundColor': '#008df1',
      'boxShadow': '1px 2px 3px 0px #0a0a0a30',
      'textAlign': 'center',
      '&.error': {
        backgroundColor: 'red',
      },
      '&:disabled': {
        backgroundColor: '#ccc',
        boxShadow: 'none',
      },
      '&:disabled.pending': {
        backgroundColor: '#66c0ff',
      },
      '&:disabled.paid': {
        backgroundColor: '#c5e2c6',
      },
    },
  })
);

export default PayDriverCell;
