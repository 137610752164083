import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { gapi } from 'admin/core/GoogleApi';
import useAndroidEnterpriseData from 'admin/androidenterprise/hooks/useAndroidEnterpriseData';
import ActiveAction from 'admin/androidenterprise/types/ActiveAction';
import Device from 'admin/androidenterprise/types/Device';
import {
  Table,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Theme,
  createStyles,
  MenuItem,
  Menu,
  Button
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import RouteLoader from 'shared/components/RouteLoader';
import CommandConfirmationDialog from './CommandConfirmationDialog';

interface TableRow {
  id: string;
  state: string;
  appliedState: string;
  policyCompliant: string;
  appliedPolicyVersion: string;
  lastPolicySyncTime: string;
  lastStatusReportTime: string;
}

function createRow(device: Device) {
  return {
    name: device.name.replace(`${process.env.REACT_APP_ANDROID_ENTERPRISE_NAME}/devices/`, ''),
    id: device.enrollmentTokenData,
    state: device.state,
    appliedState: device.appliedState,
    policyCompliant: device.policyCompliant,
    appliedPolicyVersion: device.appliedPolicyVersion,
    lastPolicySyncTime: device.lastPolicySyncTime,
    enrollmentTime: device.enrollmentTime
  };
}

const getAllDevicesFunc = () => getDevicesByPage([]);

const getDevicesByPage = async (devicesList: Device[], pageToken?: string | undefined): Promise<Device[]> => {
  const response = await gapi().client.androidmanagement.enterprises.devices.list({
    parent: process.env.REACT_APP_ANDROID_ENTERPRISE_NAME,
    pageSize: 200,
    pageToken
  });
  const updatedDevices = [...devicesList, ...response.result.devices];
  const { nextPageToken } = response.result;
  if (nextPageToken) {
    return await getDevicesByPage(updatedDevices, nextPageToken);
  }
  return updatedDevices;
};

const DeviceTable: React.FC = () => {
  const [activeAction, setActiveAction] = React.useState<undefined | ActiveAction>(undefined);
  const { error, data, loading } = useAndroidEnterpriseData<Device[]>(getAllDevicesFunc);
  const classes = useStyles();
  const history = useHistory();

  const rows = React.useMemo(() => data?.map(createRow), [data]);

  if (error) {
    return (
      <Alert elevation={5} variant='filled' severity='error'>
        {error}
      </Alert>
    );
  }

  if (loading) {
    return <RouteLoader />;
  }

  const preventDefault = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleRelActionsClick = (id: string, name: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    setActiveAction({
      element: event.currentTarget,
      id,
      name: `${process.env.REACT_APP_ANDROID_ENTERPRISE_NAME}/devices/${name}`
    });
  };

  const resetActiveAction = () => setActiveAction(undefined);

  const handleMenuClose = () => setActiveAction((a: any) => (Boolean(a) ? { ...a, element: undefined } : undefined));

  const handleReboot = () => {
    handleMenuClose();
    setActiveAction((a: any) => (Boolean(a) ? { ...a, action: 'reboot' } : undefined));
  };

  const handleDelete = () => {
    handleMenuClose();
    setActiveAction((a: any) => (Boolean(a) ? { ...a, action: 'delete' } : undefined));
  };

  return (
    <>
      {data && (
        <div className={classes.totalDevices}>
          Total Devices: <b>{data?.length}</b>
        </div>
      )}
      <TableContainer component={Paper}>
        <Table aria-label='android enterprise devices'>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>State</TableCell>
              <TableCell align='center'>Policy Compliant</TableCell>
              <TableCell align='center'>Policy Version</TableCell>
              <TableCell>Last Policy Sync Time</TableCell>
              <TableCell>Date Enrolled</TableCell>
              <TableCell align='center'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, i) => (
              <TableRow
                className={classes.clickable}
                hover
                key={`${row.id}-${i}`}
                onClick={() => history.push(`/admin/enterprise/device/${row.name}`)}
              >
                <TableCell component='th' scope='row'>
                  {row.id}
                </TableCell>
                <TableCell>{row.appliedState}</TableCell>
                <TableCell align='center'>
                  {row.policyCompliant ? (
                    <span className={classes.yes}>YES</span>
                  ) : (
                    <span className={classes.no}>NO</span>
                  )}
                </TableCell>
                <TableCell align='center'>{row.appliedPolicyVersion}</TableCell>
                <TableCell>{moment(new Date(row.lastPolicySyncTime)).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
                <TableCell>{moment(new Date(row.enrollmentTime)).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
                <TableCell align='center' onClick={preventDefault}>
                  <Button
                    aria-controls='simple-menu'
                    aria-haspopup='true'
                    onClick={handleRelActionsClick(row.id, row.name)}
                  >
                    <MoreVertIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Menu
          id='simple-menu'
          anchorEl={activeAction?.element}
          keepMounted
          open={Boolean(activeAction?.element)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleReboot}>
            <RefreshIcon fontSize='small' className={classes.menuItemIcon} />
            Reboot
          </MenuItem>
          <MenuItem onClick={handleDelete}>
            <DeleteForeverIcon fontSize='small' className={classes.menuItemIcon} />
            Delete
          </MenuItem>
        </Menu>
        {activeAction?.action && <CommandConfirmationDialog action={activeAction} onClose={resetActiveAction} />}
      </TableContainer>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    yes: {
      color: 'green'
    },
    no: {
      color: 'red'
    },
    margin: {
      margin: theme.spacing(1)
    },
    tableFooter: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    clickable: {
      cursor: 'pointer'
    },
    bold: {
      fontWeight: 700
    },
    menuItemIcon: {
      marginRight: '10px'
    },
    totalDevices: {
      padding: '0 0 20px 0'
    }
  })
);

export default DeviceTable;
