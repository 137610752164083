import React, { useRef } from 'react';
import { gapi } from 'admin/core/GoogleApi';
import useAndroidEnterpriseData from 'admin/androidenterprise/hooks/useAndroidEnterpriseData';
import { useParams } from 'react-router-dom';
import Device from 'admin/androidenterprise/types/Device';
import RouteLoader from 'shared/components/RouteLoader';
import Alert from '@material-ui/lab/Alert';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import DataList from 'admin/androidenterprise/components/DataList';

const getDeviceFunc = (id: string) => () =>
  gapi()
    .client.androidmanagement.enterprises.devices.get({
      name: `${process.env.REACT_APP_ANDROID_ENTERPRISE_NAME}/devices/${id}`
    })
    .then((resp: any) => resp.result);

const DevicePage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const getDevice = useRef(getDeviceFunc(params.id));
  const { error, data, loading } = useAndroidEnterpriseData<Device>(getDevice.current as any);
  const classes = useStyles();

  if (error) {
    return (
      <Alert elevation={5} variant='filled' severity='error'>
        An error occurred while fetching this device data. Please refresh the page and try again.
      </Alert>
    );
  }

  if (loading) {
    return <RouteLoader />;
  }

  return (
    <>
      <h1>Device: {data?.enrollmentTokenData}</h1>
      <a
        className={classes.link}
        href={`${process.env.REACT_APP_PORTAL}/device/${data?.enrollmentTokenData}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        <LinkIcon className={classes.linkIcon} fontSize='small' /> Device Page
      </a>
      <h2>Android Enterprise Data</h2>
      <DataList data={data} />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: 'flex',
      alignItems: 'center'
    },
    linkIcon: {
      marginRight: '6px',
      marginTop: '1px'
    }
  })
);

export default DevicePage;
