import { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useSafeFetch = () => {
  const history = useHistory();
  const mounted = useRef(true);

  const createSafeFetch = (method: 'GET' | 'POST' | 'PUT') => async (
    input: RequestInfo,
    init?: RequestInit & { redirectToLogin?: boolean }
  ): Promise<Response> => {
    const response = await fetch(input, {
      ...init,
      method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        ...init?.headers,
      },
      credentials: 'include',
    });
    if (
      (!init || init?.redirect) &&
      history.location.pathname !== '/signup' &&
      ((response.redirected && response.url?.includes('login')) || response.status === 403)
    ) {
      redirectToLogin();
    }
    if (!mounted.current) {
      // eslint-disable-next-line no-throw-literal
      throw { cancelled: true };
    }
    return response;
  };

  const redirectToLogin = () => {
    history.replace(`/login`);
    // eslint-disable-next-line no-throw-literal
    throw { unauthenticated: true };
  };

  const safeFetch = useRef({ get: createSafeFetch('GET'), post: createSafeFetch('POST'), put: createSafeFetch('PUT') });

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return safeFetch.current;
};

export default useSafeFetch;
