import React from 'react';
import { Button, CircularProgress, ButtonProps, makeStyles, createStyles, Theme } from '@material-ui/core';

const LoadingButton: React.FC<ButtonProps & { loading: boolean }> = props => {
  const { loading, ...buttonProps } = props;
  const classes = useStyles();
  return (
    <Button {...buttonProps} disabled={Boolean(loading || buttonProps.disabled)}>
      {!loading ? props.children : <CircularProgress size={25} className={classes.loader} />}
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loader: {
      color: '#FFF'
    }
  })
);

export default LoadingButton;
