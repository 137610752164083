import { useEffect, useState } from 'react';
import 'react-table/react-table.css';
import { gapi, GoogleAuth } from 'admin/core/GoogleApi';

const SCOPE = 'https://www.googleapis.com/auth/androidmanagement';

function useAndroidEnterpriseData<T>(exec: () => Promise<T>) {
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setData(await exec());
      } catch (e) {
        setError('A problem occurred while fetching data from Google. Please refresh the page and try again.');
      }
    };

    const initClient = () => {
      // Retrieve the discovery document for version 3 of Google Drive API.

      // In practice, your app can retrieve one or more discovery documents.
      var discoveryUrl = 'https://androidmanagement.googleapis.com/$discovery/rest?version=v1';

      // Initialize the gapi.client object, which app uses to make API requests.
      // Get API key and client ID from API Console.
      // 'scope' field specifies space-delimited list of access scopes.
      gapi()
        .client.init({
          apiKey: process.env.REACT_APP_ANDROID_ENTERPRISE_API_KEY,
          discoveryDocs: [discoveryUrl],
          clientId: process.env.REACT_APP_ANDROID_ENTERPRISE_CLIENT_ID,
          scope: SCOPE,
          ux_mode: 'redirect'
        })
        .then(function() {
          if (!isAuthorized()) {
            GoogleAuth().signIn();
          } else {
            fetchData();
          }
        });
    };

    // Load the API's client and auth2 modules.
    // Call the initClient function after the modules load.
    gapi().load('client:auth2', initClient);
  }, [exec]);

  return { loading: !data && !error, data, error, signOut: () => GoogleAuth().disconnect() };
}

const isAuthorized = () => {
  const user = GoogleAuth().currentUser?.get();
  return user?.hasGrantedScopes(SCOPE);
};

export default useAndroidEnterpriseData;
