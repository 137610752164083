import React from 'react';
import { AppBar as MuiAppBar, Toolbar, Button } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Logo from 'giftcards/assets/logo/flatlogo.svg';
import { isLocal } from 'shared/utils/DevUtils';

const AppBar: React.FC = () => {
  const classes = useStyles();
  return (
    <MuiAppBar position='fixed' className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <a href='/'>
          <img src={Logo} alt='Paneau' className={classes.logo} />
        </a>
        <Button role='link' onClick={logout} className={classes.logout}>
          Logout
        </Button>
      </Toolbar>
    </MuiAppBar>
  );
};

const logout = async () => {
  try {
    const domain = isLocal() ? process.env.REACT_APP_PORTAL : '';
    await fetch(`${domain}/api/logout`, { method: 'GET', credentials: 'include' });
  } catch (e) {
    console.error('logout failure');
  }
  if (typeof window !== 'undefined') {
    window.location.href = '/login';
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1600
    },
    toolbar: {
      justifyContent: 'space-between'
    },
    logo: {
      height: '2rem',
      minHeight: '2rem'
    },
    logout: {
      color: '#FFF !important'
    }
  })
);

export default AppBar;
