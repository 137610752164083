import { CssBaseline, CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navigation from 'admin/core/Navigation';
import DriverDash from 'admin/driverdash';
import AndroidEnterprise from 'admin/androidenterprise';
import AppBar from 'shared/components/AppBar';
import AppTheme from 'shared/components/AppTheme';
import useCheckAuthentication from 'shared/hooks/useCheckAuthentication';

const App: React.FC = () => (
  <Router>
    <AppTheme>
      <AppContent />
    </AppTheme>
  </Router>
);

const AppContent = () => {
  const classes = useStyles();
  const { loading, authenticated } = useCheckAuthentication(true);

  if (loading) {
    return (
      <div className={classes.fullPage}>
        <CircularProgress size='6rem' />
      </div>
    );
  }

  if (!authenticated) {
    return <div className={classes.fullPage}>Redirecting to login...</div>;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar />
      <Navigation />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route path='/admin/driverdash' component={DriverDash} />
          <Route path='/admin/enterprise' component={AndroidEnterprise} />
          <Route path='*'>
            <div>404</div>
          </Route>
        </Switch>
      </main>
    </div>
  );
};

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    toolbar: theme.mixins.toolbar,
    fullPage: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
);

export default App;
