import { useEffect, useState } from 'react';
import useSafeFetch from './useSafeFetch';

const useCheckAuthentication = (admin: boolean) => {
  const { get } = useSafeFetch();
  const [authenticated, setAuthenticated] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    checkAuthenticated(admin, setAuthenticated, get);
  }, [get, admin]);

  return { loading: authenticated === undefined, authenticated };
};

const checkAuthenticated = async (admin: boolean, setAuthenticated: (a: boolean) => void, get: any) => {
  try {
    await get(`${process.env.REACT_APP_PORTAL}/${admin ? 'admin' : 'home'}`);
    setAuthenticated(true);
  } catch (e) {
    if (!e.cancelled) {
      setAuthenticated(!Boolean(e.unauthenticated));
    }
  }
};

export default useCheckAuthentication;
