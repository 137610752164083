import React, { useEffect, useRef, useState } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import moment from 'moment';
import { useLocation, useHistory, useParams, Link } from 'react-router-dom';
import { makeStyles, Theme, createStyles, Grid, Button, CircularProgress } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import queryString from 'query-string';
import { Status } from 'admin/driverdash/components/DriverTable/PayDriverCell';
import NotesBox from 'admin/common/components/NotesBox';
import NotesForm from 'admin/common/components/NotesForm';
import RouteLoader from 'shared/components/RouteLoader';
import useSafeFetch from 'shared/hooks/useSafeFetch';
import DatePicker, { defaultDateRange, formatDate } from 'shared/components/DatePicker';
import { formatForAmCharts } from 'admin/common/util/AmChartsUtil';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

am4core.useTheme(am4themes_animated);

const initChart = () => {
  const chart = am4core.create('chartdivuser', am4charts.XYChart);

  chart.paddingRight = 20;

  const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.grid.template.location = 0;
  dateAxis.title.text = 'Date';

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.title.text = 'Driver Hours';

  const series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.dateX = 'date';
  series.dataFields.valueY = 'value';

  series.tooltipText = '{valueY.value}';
  chart.cursor = new am4charts.XYCursor();

  const scrollbarX = new am4charts.XYChartScrollbar();
  scrollbarX.series.push(series);
  chart.scrollbarX = scrollbarX;

  return chart;
};

const DriverProfile: React.FC = props => {
  const { get, post } = useSafeFetch();
  const params = useParams<{ did: string }>();
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();

  const queryParams = queryString.parse(location.search);
  const startDate = (queryParams.startDate as string) || formatDate(defaultDateRange.startDate);
  const endDate = (queryParams.endDate as string) || formatDate(defaultDateRange.endDate);

  const [driver, setDriver] = useState<any>(null);
  const [driverScreenShots, setDriverScreenShots] = useState<any[]>();
  const [, setStatus] = useState<Status | null>(null);
  const chart = useRef<any>(null);
  const refreshHoursInterval = useRef<number | undefined>(undefined);

  useEffect(() => {
    refreshDriverData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.did, startDate, endDate]);

  useEffect(() => {
    refreshScreenshots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driver]);

  useEffect(() => {
    if (driver && !chart.current) {
      chart.current = initChart();
    }
  }, [driver]);

  useEffect(() => {
    refreshDriverHours();
    return () => {
      clearInterval(refreshHoursInterval.current);
      chart.current?.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driver, startDate, endDate]);

  const refreshDriverHours = async () => {
    if (driver) {
      const refreshChart = async () => {
        try {
          const response = await get(
            `${process.env.REACT_APP_PORTAL}/driver/hours?username=${driver.username}&from_date=${startDate}&to_date=${endDate}`
          );
          const hours = await response.json();
          chart.current.data = formatForAmCharts(hours);
        } catch (e) {
          if (!e.cancelled) {
            setStatus(Status.Error);
            console.log(e);
          }
        }
      };

      refreshChart();
      refreshHoursInterval.current = setInterval(refreshChart, 10000000) as any;
    }
  };

  const refreshDriverData = async () => {
    try {
      const response = await get(
        `${process.env.REACT_APP_PORTAL}/driver/${params.did}?from_date=${startDate}&to_date=${endDate}`
      );
      const json = await response.json();
      setDriver(json.driver);
    } catch (e) {
      if (!e.cancelled) {
        // do nothing
      }
    }
  };

  const refreshScreenshots = async () => {
    try {
      if (driver) {
        const response = await await get(
          `${process.env.REACT_APP_PORTAL}/driver/screenshots?driver_id=${driver.username}`
        );
        const json = await response.json();
        setDriverScreenShots(json.screenshots || []);
      }
    } catch (e) {
      if (!e.canceelled) {
        setStatus(Status.Error);
        console.log(e);
      }
    }
  };

  const activate = () => {
    toggleActivation('activate');
  };

  const deactivate = () => {
    toggleActivation('deactivate');
  };

  const toggleActivation = async (action: string) => {
    try {
      setStatus(Status.Pending);
      const response = await get(`${process.env.REACT_APP_PORTAL}/driver/${action}/${driver.username}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const { status } = response;
      if (status >= 200 && status < 300) {
        setStatus(Status.Paid);
        const json = await response.json();
        setDriver({ ...driver, deactivate_date: json.on });
      } else {
        setStatus(Status.Error);
      }
    } catch (e) {
      if (!e.cancelled) {
        setStatus(Status.Error);
        console.log(e);
      }
    }
  };

  const addNote = async (note: string) => {
    try {
      const response = await post(`${process.env.REACT_APP_PORTAL}/driver/addnote`, {
        body: JSON.stringify({
          username: driver.email,
          note
        })
      });

      const { status } = response;
      if (status >= 200 && status < 300) {
        await refreshDriverData();
      } else {
        // TODO
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (!driver) {
    return <RouteLoader />;
  }

  const hasScreenshots = driverScreenShots && driverScreenShots.length ? true : undefined;

  return (
    <>
      <Link to='/admin/driverdash'>
        <ArrowBackIcon />
      </Link>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.textCenter}>
          <h2>{driver.name}</h2>
          <Divider />
          <p className={classes.textCenter}>{driver.email}</p>
          <p className={classes.textCenter}>
            <a href={driver.phone_number} target='_blank' rel='noopener noreferrer'>
              {driver.phone_number}
            </a>
          </p>
          <div id='chartdivuser' style={{ width: '100%', height: '500px' }}></div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <DatePicker
              initDateRange={{
                startDate: moment(startDate),
                endDate: moment(endDate)
              }}
              onDateSelected={(startDate: moment.Moment, endDate: moment.Moment) => {
                history.push(
                  `/admin/driverdash/profile/${params.did}?startDate=${formatDate(startDate)}&endDate=${formatDate(
                    endDate
                  )}`
                );
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.stats}>
          <h3>Driver State: {driver.deactivate_date === null ? 'Active' : 'Deactivated'}</h3>
          <Button
            variant='contained'
            color='secondary'
            onClick={driver.deactivate_date === null ? deactivate : activate}
          >
            {driver.deactivate_date === null ? 'Deactivate' : 'Activate'}
          </Button>

          <Divider />

          <InfoRow>
            <InfoTitle>
              Active hours from {startDate.split('T')[0]} to {endDate.split('T')[0]}:
            </InfoTitle>
            <InfoData>{driver.drive_hours || 0}</InfoData>
          </InfoRow>
          <InfoRow>
            <InfoTitle>All time avg. hours per week:</InfoTitle>
            <InfoData>{driver.average_week_drive_hours}</InfoData>
          </InfoRow>

          <Divider />

          <InfoRow>
            <InfoTitle>
              Idle hours from {startDate.split('T')[0]} to {endDate.split('T')[0]}:
            </InfoTitle>
            <InfoData>{driver.idle_hours || 0}</InfoData>
          </InfoRow>
          <InfoRow>
            <InfoTitle>All time idle rate:</InfoTitle>
            <InfoData>{driver.idle_rate}</InfoData>
          </InfoRow>

          <Divider />

          <InfoRow>
            <InfoTitle>Date joined:</InfoTitle>
            <InfoData>{driver.create_date}</InfoData>
          </InfoRow>
          <InfoRow>
            <InfoTitle>Market:</InfoTitle>
            <InfoData>San Francisco, CA</InfoData>
          </InfoRow>

          <Divider />

          <InfoRow>
            <InfoTitle>Device:</InfoTitle>
            <InfoData>
              <a href={`${process.env.REACT_APP_PORTAL}/device/${driver.device_id}`}>{driver.device_name}</a>
            </InfoData>
          </InfoRow>
          <InfoRow>
            <InfoTitle>App version:</InfoTitle>
            <InfoData>{driver.tablet_software_version}</InfoData>
          </InfoRow>

          <Divider />
          {hasScreenshots && (
            <div className={classes.carousel}>
              <Carousel
                slidesPerPage={3}
                itemWidth={250}
                arrows
                breakpoints={{
                  1000: {
                    // these props will be applied when screen width is less than 1000px
                    slidesPerPage: 2
                  },
                  500: {
                    slidesPerPage: 1
                  }
                }}
              >
                {driverScreenShots?.map(screenshot => (
                  <DriverScreenShot imageUrl={screenshot.image_url} date={screenshot.create_date}></DriverScreenShot>
                ))}
              </Carousel>
            </div>
          )}
          {driverScreenShots === undefined && <CircularProgress size='4rem' />}
          {driverScreenShots?.length === 0 && <p>No Screenshots</p>}
          <Divider />
        </Grid>
        <Grid item xs={12} className={classes.textCenter}>
          <ul>
            {driver.notes?.map((note: any, index: number) => (
              <li key={index}>
                <NotesBox note={note.note} date={note.date} author={note.author} />
              </li>
            ))}
          </ul>
          <NotesForm onSubmit={addNote} />
        </Grid>
      </Grid>
    </>
  );
};

const Divider = () => {
  const classes = useStyles();
  return <hr className={classes.divider} />;
};

const InfoRow: React.FC = props => {
  const classes = useStyles();
  return <div className={classes.infoRow}>{props.children}</div>;
};

const InfoTitle: React.FC = props => {
  const classes = useStyles();
  return <span className={classes.infoTitle}>{props.children}</span>;
};

const InfoData: React.FC = props => <span>{props.children}</span>;

interface DriverScreenShotProps {
  imageUrl: string;
  date: string;
}

const DriverScreenShot: React.FC<DriverScreenShotProps> = props => {
  const classes = useStyles();
  return (
    <div>
      <img alt='screenshot' className={classes.screenshot} src={props.imageUrl} />
      <div>{props.date}</div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    center: {
      alignItems: 'center',
      justifyContent: 'center'
    },
    textCenter: {
      textAlign: 'center'
    },
    stats: {
      textAlign: 'center',
      fontSize: '16px'
    },
    infoRow: {
      margin: '0.5rem'
    },
    infoTitle: {
      fontWeight: 700,
      marginRight: '0.5rem'
    },
    divider: {
      margin: '1.5rem 0'
    },
    screenshot: {
      maxHeight: '500px',
      maxWidth: '500px'
    },
    carousel: {
      'margin': '0 auto',
      '& .BrainhubCarousel': {
        justifyContent: 'center'
      },
      '& .BrainhubCarousel__trackContainer': {
        [theme.breakpoints.up('sm')]: {
          maxWidth: '250px'
        },
        [theme.breakpoints.up('md')]: {
          maxWidth: '500px'
        },
        [theme.breakpoints.up('lg')]: {
          maxWidth: '750px'
        },
        [theme.breakpoints.up('xl')]: {
          maxWidth: '1000px'
        }
      }
    }
  })
);

export default DriverProfile;
