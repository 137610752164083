import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import PayDriverCell from './PayDriverCell';
import { DateRange, formatDate } from 'shared/components/DatePicker';

type DriverTableProps = {
  data: any;
  dateRange: DateRange;
};

const DriverTable: React.FC<DriverTableProps> = props => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  let history = useHistory();

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (props: any) => <span className='name'>{props.value}</span> // Custom cell components!
    },
    {
      Header: 'Phone Number',
      accessor: 'phone_number',
      Cell: (props: any) => <span className='number'>{props.value}</span> // Custom cell components!
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: (props: any) => <span className='email'>{props.value}</span>
    },
    {
      Header: 'Full Time Driver',
      accessor: 'average_week_drive_hours',
      Cell: (row: any) => (
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#dadada',
            borderRadius: '2px'
          }}
        >
          <div
            style={{
              width: `${(row.value / 40) * 100 > 100 ? 100 : (row.value / 40) * 100}%`,
              height: '100%',
              backgroundColor: row.value > 33 ? '#85cc00' : row.value > 16.5 ? '#ffbf00' : '#ff2e00',
              borderRadius: '2px',
              transition: 'all .2s ease-out'
            }}
          />
        </div>
      )
    },
    {
      Header: 'Live Status',
      accessor: 'live_status',
      Cell: (row: any) => (
        <span>
          <span
            style={{
              color: row.value === 'offline' ? '#ff2e00' : row.value === 'idle' ? '#ffbf00' : '#57d500',
              transition: 'all .3s ease'
            }}
          >
            &#x25cf;
          </span>{' '}
          {row.value === 'driving' ? 'Driving' : row.value === 'idle' ? `Idle` : 'Offline'}
        </span>
      )
    },
    {
      Header: 'Active Status',
      accessor: 'deactivate_date',
      Cell: (row: any) => (
        <span>
          <span
            style={{
              color: row.value === null ? '#57d500' : '#ff2e00',
              transition: 'all .3s ease'
            }}
          >
            &#x25cf;
          </span>{' '}
          {row.value === null ? 'Active' : 'Inactive'}
        </span>
      ),
      filterMethod: (filter: any, row: any, column: any) => {
        const id = filter.pivotId || filter.id;
        let true_value = row[id] !== null ? 'Inactive' : 'Active';
        return true_value.toLowerCase().startsWith(filter.value.toLowerCase());
      }
    },
    {
      Header: 'Pay Driver',
      accessor: 'email',
      Cell: (row: any) => <PayDriverCell id={row.value} />
    }
  ];

  const rowSelected = (index: number) => {
    setSelectedIndex(index);
    if (props.data && props.data.length > 0) {
      const driverSelected = props.data[index];
      const startDate = formatDate(props.dateRange.startDate);
      const endDate = formatDate(props.dateRange.endDate);
      history.push(`/admin/driverdash/profile/${driverSelected.id}?startDate=${startDate}&endDate=${endDate}`);
    }
  };

  return (
    <div>
      <ReactTable
        filterable
        data={props.data}
        columns={columns}
        getTrProps={(state: any, rowInfo: any) => {
          if (rowInfo && rowInfo.row) {
            return {
              onClick: (e: any) => {
                rowSelected(rowInfo.index);
              },
              style: {
                background: rowInfo.index === selectedIndex ? '#00afec' : 'white',
                color: rowInfo.index === selectedIndex ? 'white' : 'black'
              }
            };
          } else {
            return {};
          }
        }}
        defaultFilterMethod={(filter: any, row: any, column: any) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true;
        }}
      />
    </div>
  );
};

export default DriverTable;
