import { Container } from '@material-ui/core';
import React from 'react';
import { Route } from 'react-router-dom';
import DevicesPage from 'admin/androidenterprise/components/DevicesPage';
import DevicePage from 'admin/androidenterprise/components/DevicePage';
import PolicyPage from 'admin/androidenterprise/components/PolicyPage';

const AndroidEnterprise: React.FC = () => (
  <Container>
    <Route exact path='/admin/enterprise' component={DevicesPage}></Route>
    <Route exact path='/admin/enterprise/device/:id' component={DevicePage}></Route>
    <Route exact path='/admin/enterprise/policy' component={PolicyPage}></Route>
  </Container>
);

export default AndroidEnterprise;
