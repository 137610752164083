import { Container } from '@material-ui/core';
import React from 'react';
import {
  // Switch,
  Route
} from 'react-router-dom';
import 'react-table/react-table.css';
import DriverMain from './components/DriverMain';
import DriverProfile from './components/DriverProfile';

const DriverDash: React.FC = () => {
  return (
    <Container>
      <Route exact path='/admin/driverdash' component={DriverMain}></Route>
      <Route exact path='/admin/driverdash/profile/:did' component={DriverProfile}></Route>
    </Container>
    // Note: The router is only needed in the base app.
  );
};

export default DriverDash;
