import React, { useRef } from 'react';
import { gapi } from 'admin/core/GoogleApi';
import useAndroidEnterpriseData from 'admin/androidenterprise/hooks/useAndroidEnterpriseData';
import RouteLoader from 'shared/components/RouteLoader';
import Alert from '@material-ui/lab/Alert';
import DataList from 'admin/androidenterprise/components/DataList';

const getPolicyFunc = () =>
  gapi()
    .client.androidmanagement.enterprises.policies.get({
      name: `${process.env.REACT_APP_ANDROID_ENTERPRISE_POLICY_NAME}`
    })
    .then((resp: any) => resp.result);

const PolicyPage: React.FC = () => {
  const getDevice = useRef(getPolicyFunc);
  const { error, data, loading } = useAndroidEnterpriseData<any>(getDevice.current as any);

  if (error) {
    return (
      <Alert elevation={5} variant='filled' severity='error'>
        An error occurred while fetching the policy. Please refresh the page and try again.
      </Alert>
    );
  }

  if (loading) {
    return <RouteLoader />;
  }

  return (
    <>
      <h1>Device Policy</h1>
      <DataList data={data} />
    </>
  );
};

export default PolicyPage;
